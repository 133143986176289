
	import { Vue, Component, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, ContactModalType, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations, actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import { validateEmail } from '@pixcap/ui-core/utils/ValidationUtils';
	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import InputWrapper from '@pixcap/ui-library/components/InputWrapper.vue';
	import EditableTextarea from '@pixcap/ui-library/components/EditableTextarea.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';

	@Component({
		name: 'ContactModal',
		components: {
			Modal,
			InputWrapper,
			EditableTextarea,
			Button,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				isSendingMessage: (state: UserState) => state.isSendingMessage,
				showContactModal: (state: UserState) => state.showContactModal,
			}),
		},
	})
	export default class ContactModal extends Vue {
		isSendingMessage: UserState['isSendingMessage'];
		showContactModal: UserState['showContactModal'];

		name = '';
		email = '';
		message = '';
		errorMsg: { name?: string; email?: string } = {};

		$refs: {
			refContactUsForm: HTMLFormElement;
		};

		@Watch('name')
		onNameChange(name) {
			// @ts-ignore
			this.updateError('name', name != '', this.$t ? this.$t('navbar.contact_us_form.error_name') : '**Name cannot be empty');
		}

		@Watch('email')
		onEmailChange(email) {
			// @ts-ignore
			this.updateError('email', validateEmail(email), this.$t ? this.$t('navbar.contact_us_form.error_email') : '**Email is invalid');
		}

		get disableSubmit() {
			return !(this.name && this.email && this.message && Object.keys(this.errorMsg).length === 0 && !this.isSendingMessage);
		}

		updateError(key: 'name' | 'email', isValid: boolean, message?: string) {
			const errors = this.errorMsg;
			if (isValid) {
				const currentErrors = { ...errors };
				delete currentErrors[key];
				this.errorMsg = currentErrors;
			} else {
				this.errorMsg = { ...errors, [key]: message };
			}
		}

		resetTabIndex(e) {
			e.preventDefault();
			let form = this.$refs.refContactUsForm;
			form.getElementsByTagName('input')[0].focus();
		}
		postMessageRequest() {
			if (this.disableSubmit) return;
			UserActions.contactUS(this.$store, {
				name: this.name,
				email: this.email,
				message: this.message,
				isCustomPricing: this.showContactModal === ContactModalType.SHOW_FOR_PRICING,
			});
		}
		handleCloseModal() {
			UserMutations.setShowContactModal(this.$store, null);
		}
	}
