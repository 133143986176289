
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { PersonalData } from '@pixcap/ui-core/models/store/user.interface';

	@Component({
		name: 'Avatar',
	})
	export default class Avatar extends Vue {
		@Prop() readonly user: PersonalData['user'];
		@Prop() readonly variant: undefined | 'lg' | 'md' | 'md2';

		get classes(): string {
			let classes = 'avatar';
			if (this.variant) classes += ` avatar--${this.variant}`;
			return classes;
		}

		get avatarUrl() {
			return this.user.avatarUrl;
		}

		get firstLetter() {
			if (!this.user.name) return '';
			return this.user.name.slice(0, 1).toUpperCase();
		}
	}
