
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import { Getters as UserGetters, GetterTypes as UserGetterTypes, NAMESPACE as USER_NAMESPACE } from '@pixcap/ui-core/models/store/user.interface';
	import { mapGetters } from 'vuex';

	@Component({
		name: 'ProItemTag',
		components: {
			IconCrown,
			CaptionText,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
		},
	})
	export default class ProItemTag extends Vue {
		@Prop({ default: true }) rounded: boolean;
		@Prop({ default: false }) isPurchased: boolean;

		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;
	}
