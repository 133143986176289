
	import { Component, Vue } from 'vue-property-decorator';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';

	@Component({
		name: 'NewBadge',
		components: {
			CaptionText,
		},
	})
	export default class NewBadge extends Vue {}
