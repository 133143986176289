
	import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
	import CornerResizeHandler from '@pixcap/ui-library/components/editable-box/CornerResizeHandler.vue';
	import ResizeHandler from '@pixcap/ui-library/components/editable-box/ResizeHandler.vue';
	import { getResizeBounding } from '@pixcap/ui-core/utils/getResizeBounding';
	import {
		CORNER_HANDLERS,
		CornerDirection,
		HORIZONTAL_EDGE_HANDLERS,
		VERTICAL_EDGE_HANDLERS,
	} from '@pixcap/ui-core/models/store/resizeable.interface';

	@Component({
		name: 'Cropper',
		components: {
			ResizeHandler,
			CornerResizeHandler,
		},
	})
	export default class Cropper extends Vue {
		@Prop() imageBox: { width: number; height: number };
		@Prop() imageUrl: string;
		@Prop() imageCropBox: { x?: number; y?: number; w?: number; h?: number };
		@Prop() imageRenderBox: { width: number; height: number };
		HORIZONTAL_EDGE_HANDLERS = HORIZONTAL_EDGE_HANDLERS;
		VERTICAL_EDGE_HANDLERS = VERTICAL_EDGE_HANDLERS;
		CORNER_HANDLERS = CORNER_HANDLERS;
		imageToCrop = null;

		_onMoveMouseMove = null;
		_onMoveMouseUp = null;
		_moveContext: { isMoving?: boolean; initialX?: number; initialY?: number; initialClientX?: number; initialClientY?: number } = null;
		_handleResizeMouseMove = null;
		_handleResizeMouseUp = null;
		_resizeContext: { aspectRatio?: number; direction?: CornerDirection; isResizing?: boolean } = null;
		_handleKeyPress = null;
		startX: any;
		startY: any;

		@Watch('imageCropBox')
		@Watch('imageUrl')
		onImageCropInfoChange() {
			this.initImageToCrop();
		}

		get boardScale() {
			return this.imageRenderBox.width / this.imageBox.width;
		}

		get backgroundCropBoxStyle() {
			const boardScale = this.boardScale;
			return {
				width: `${this.imageToCrop.cropBox.w * boardScale}px`,
				height: `${this.imageToCrop.cropBox.h * boardScale}px`,
				left: `${this.imageToCrop.cropBox.x * boardScale}px`,
				top: `${this.imageToCrop.cropBox.y * boardScale}px`,
			};
		}

		onMoveMouseDown(e: MouseEvent | TouchEvent) {
			if (e instanceof MouseEvent) {
				this._moveContext.initialX = e.clientX;
				this._moveContext.initialY = e.clientY;
			} else if (e instanceof TouchEvent) {
				this._moveContext.initialX = e.touches[0].clientX;
				this._moveContext.initialY = e.touches[0].clientY;
			}
			this._moveContext.initialClientX = this.imageToCrop.cropBox.x;
			this._moveContext.initialClientY = this.imageToCrop.cropBox.y;
			document.addEventListener('mousemove', this._onMoveMouseMove);
			document.addEventListener('touchmove', this._onMoveMouseMove);
			document.addEventListener('mouseup', this._onMoveMouseUp);
			document.addEventListener('touchend', this._onMoveMouseUp);
		}

		onMoveMouseMove(e: MouseEvent | TouchEvent) {
			if (!this._moveContext.isMoving) this._moveContext.isMoving = true;
			let clientX, clientY;
			if (e instanceof MouseEvent) {
				clientX = e.clientX;
				clientY = e.clientY;
			} else if (e instanceof TouchEvent) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			}
			const dx = clientX - this._moveContext.initialX;
			const dy = clientY - this._moveContext.initialY;
			let cropX = this._moveContext.initialClientX + dx / this.boardScale;
			let cropY = this._moveContext.initialClientY + dy / this.boardScale;
			if (e.shiftKey) {
				cropX = Math.min(cropX, 0);
				cropY = Math.min(cropY, 0);
				cropX = Math.max(cropX, -this.imageToCrop.cropBox.w + this.imageBox.width);
				cropY = Math.max(cropY, -this.imageToCrop.cropBox.h + this.imageBox.height);
			}
			this._updateImageToCrop(this.imageToCrop.cropBox, 'x', cropX);
			this._updateImageToCrop(this.imageToCrop.cropBox, 'y', cropY);
		}

		onMoveMouseUp(e: MouseEvent | TouchEvent) {
			this._moveContext.isMoving = false;
			this.startX = null;
			this.startY = null;
			document.removeEventListener('mousemove', this._onMoveMouseMove);
			document.removeEventListener('touchmove', this._onMoveMouseMove);
			document.removeEventListener('mouseup', this._onMoveMouseUp);
			document.removeEventListener('touchend', this._onMoveMouseUp);
		}

		handleResize(e: MouseEvent | TouchEvent, direction: CornerDirection) {
			this._resizeContext = { aspectRatio: this.imageToCrop.cropBox.w / this.imageToCrop.cropBox.h, direction };
			if (e instanceof TouchEvent) {
				this.startX = e.touches[0].clientX;
				this.startY = e.touches[0].clientY;
			}

			document.addEventListener('mousemove', this._handleResizeMouseMove);
			document.addEventListener('touchmove', this._handleResizeMouseMove);
			document.addEventListener('mouseup', this._handleResizeMouseUp);
			document.addEventListener('touchend', this._handleResizeMouseUp);
		}

		handleResizeMouseMove(e: MouseEvent | TouchEvent) {
			if (this._resizeContext.isResizing) this._resizeContext.isResizing = true;

			let movementX, movementY;
			if (e instanceof MouseEvent) {
				movementX = e.movementX;
				movementY = e.movementY;
			} else if (e instanceof TouchEvent) {
				const touch = e.touches[0];
				if (this.startX && this.startY) {
					movementX = touch.clientX - this.startX;
					movementY = touch.clientY - this.startY;
				}
				this.startX = touch.clientX;
				this.startY = touch.clientY;
			}
			console.log(movementX, movementY);

			const xDelta = movementX;
			const yDelta = movementY;
			const boardScale = this.boardScale;
			const newBounding = getResizeBounding(
				{
					width: this.imageToCrop.cropBox.w * boardScale,
					height: this.imageToCrop.cropBox.h * boardScale,
					left: this.imageToCrop.cropBox.x * boardScale,
					top: this.imageToCrop.cropBox.y * boardScale,
				},
				this._resizeContext.direction,
				this._resizeContext.aspectRatio,
				xDelta,
				yDelta
			);

			this._updateImageToCrop(this.imageToCrop, 'cropBox', {
				h: newBounding.height / boardScale,
				w: newBounding.width / boardScale,
				x: newBounding.left / boardScale || this.imageToCrop.cropBox.x,
				y: newBounding.top / boardScale || this.imageToCrop.cropBox.y,
			});
		}

		handleResizeMouseUp(e: MouseEvent | TouchEvent) {
			e.preventDefault();
			e.stopPropagation();
			this._resizeContext.isResizing = false;
			document.removeEventListener('mousemove', this._handleResizeMouseMove);
			document.removeEventListener('touchmove', this._handleResizeMouseMove);
			document.removeEventListener('mouseup', this._handleResizeMouseUp);
			document.removeEventListener('touchend', this._handleResizeMouseUp);
		}

		handleScaleCropbox(e: WheelEvent) {
			// const scale = e.deltaY * 0.01;
			// const cropBox = this.imageToCrop.cropBox;
			// this._updateImageToCrop(this.imageToCrop, 'cropBox', {
			// 	h: cropBox.h * scale,
			// 	w: cropBox.w * scale,
			// 	x: cropBox.x * scale,
			// 	y: cropBox.y * scale,
			// });
		}

		handleExitCropMode() {
			this.$emit('onCropImageFinish', {
				url: this.imageToCrop.url,
				cropBox: { ...this.imageToCrop.cropBox },
			});
		}

		handleKeyPress(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				this.handleExitCropMode();
			}
		}

		_updateImageToCrop(object, key: any, value: any) {
			this.$set(object, key, value);
			this.$emit('onChange', this.imageToCrop);
		}

		initImageToCrop() {
			this.imageToCrop = {
				url: this.imageUrl,
				cropBox: { ...this.imageCropBox },
			};
		}

		created() {
			this._onMoveMouseMove = this.onMoveMouseMove.bind(this);
			this._onMoveMouseUp = this.onMoveMouseUp.bind(this);
			this._handleResizeMouseMove = this.handleResizeMouseMove.bind(this);
			this._handleResizeMouseUp = this.handleResizeMouseUp.bind(this);
			this._handleKeyPress = this.handleKeyPress.bind(this);
			this._moveContext = { isMoving: false, initialX: null, initialY: null };
			this._resizeContext = {};
			this.initImageToCrop();
		}

		mounted() {
			document.addEventListener('keydown', this._handleKeyPress);
		}

		beforeDestroy() {
			document.removeEventListener('keydown', this._handleKeyPress);
		}
	}
