
	import { Component, Vue } from 'vue-property-decorator';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';

	/// icons list
	import IconCircleCheck from '@pixcap/ui-library/components/Icons/IconCircleCheck.vue';
	import IconCircleClose from '@pixcap/ui-library/components/Icons/IconCircleClose.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import IconInfo from '@pixcap/ui-library/components/Icons/IconInfo.vue';
	import IconWarning from '@pixcap/ui-library/components/Icons/IconWarning.vue';

	@Component({
		name: 'Notification',
		components: {
			IconClose,
			IconCircleCheck,
			IconInfo,
			IconCircleClose,
			IconWarning,
		},
	})
	export default class Notification extends Vue {
		NotificationType = NotificationType;
		item: { type: string; data: any; text: any; title: any };
		close: any;

		notificationClickableClass(data) {
			if (data && data.callback) return 'notification__content--click';
			return '';
		}
		handleClickNotificationTitle(e: MouseEvent, data) {
			if (data && data.callback && typeof data.callback === 'function') {
				e.stopPropagation();
				e.preventDefault();
				data.callback();
			}
		}
	}
