
	import { Component, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { IExportState, NAMESPACE as EXPORT_NAMESPACE, PixRenderExportType } from '@pixcap/ui-core/models/store/projectexports.interface';
	import { actionsWrapper as ExportActions, mutationsWrapper as ExportMutations } from '@pixcap/ui-core/store/projectexports';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'CancelServerExportModal',
		components: {
			BaseModal,
			Button,
			BodyText,
		},
		computed: {
			...mapState(EXPORT_NAMESPACE, {
				serverExportItemToCancel: (state: IExportState) => state.serverExportItemToCancel,
				selectedServerExportItem: (state: IExportState) => state.selectedServerExportItem,
				serverExportItems: (state: IExportState) => state.serverExportItems,
			}),
		},
	})
	export default class CancelServerExportModal extends Vue {
		serverExportItemToCancel: IExportState['serverExportItemToCancel'];
		selectedServerExportItem: IExportState['selectedServerExportItem'];
		serverExportItems: IExportState['serverExportItems'];

		get isCancelExportItemGif() {
			const cancelingRenderItem = this.serverExportItems?.find((item) => item.renderId === this.serverExportItemToCancel);
			return cancelingRenderItem?.exportType === PixRenderExportType.GIF;
		}

		handleCancelItem() {
			ExportActions.cancelServerRenderExport(this.$store, { renderId: this.serverExportItemToCancel, shouldRemove: false });
			if (this.selectedServerExportItem == this.serverExportItemToCancel) ExportMutations.setSelectedServerExportItem(this.$store, null);
			this.hnadleClose();
		}

		hnadleClose() {
			ExportMutations.setServerExportItemToCancel(this.$store, null);
		}
	}
