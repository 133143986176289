
	import { Component, Vue, Prop } from 'vue-property-decorator';
	/// icons list
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';

	@Component({
		name: 'NewModal',
		components: {
			IconClose,
		},
	})
	export default class NewModal extends Vue {
		@Prop() readonly width: number;
		@Prop() readonly height: number;
		@Prop() readonly initLeft: number; // initial offset left
		@Prop() readonly initTop: number; // initial offset top
		@Prop() readonly title: string;
		@Prop() readonly withFooter: boolean;
		@Prop({ default: true }) readonly disableTabKey: boolean;
		@Prop() readonly withoutHeader: boolean;
		@Prop() readonly withCloseButton: boolean;
		@Prop() readonly contentStyle;
		@Prop() readonly propsToBind: any;
		@Prop() readonly isDraggable: boolean;

		enableDrag = false;
		dragStartPositionX = 0;
		dragStartPositionY = 0;
		windowDimension: { width: number; height: number } = {
			width: window.innerWidth,
			height: window.innerHeight,
		};

		resizeEventObject: EventListenerObject;
		mouseMoveEventObject: EventListenerObject;
		mouseUpEventObject: EventListenerObject;

		$refs: {
			['modal-container']: HTMLElement;
		};

		get modalContentStyle() {
			let size = 95;
			if (!this.withFooter) size -= 55;
			if (this.withoutHeader) size -= 40;
			return { height: `calc(100% - ${size}px)`, ...this.contentStyle };
		}

		get modalContainerStyle() {
			const { width: windowWidth, height: windowHeight } = this.windowDimension;
			const width = this.width || 400;
			const height = this.height || 400;
			const modalContainerStyle: any = { width: `${width}px`, height: `${height}px` };
			if (this.isDraggable || this.initLeft != null || this.initTop != null) {
				const left = this.initLeft || (windowWidth - width) / 2;
				const top = this.initTop || (windowHeight - height) / 2;
				modalContainerStyle.left = `${left}px`;
				modalContainerStyle.top = `${top}px`;
			}
			return modalContainerStyle;
		}

		handleMouseDown(event) {
			if (this.isDraggable) {
				this.dragStartPositionX = this.$refs['modal-container'].offsetLeft - event.pageX;
				this.dragStartPositionY = this.$refs['modal-container'].offsetTop - event.pageY;
				this.toggleDragStatus({ drag: true });
			}
		}

		handleMouseMove(e) {
			if (this.isDraggable && this.enableDrag) {
				const dragOffsetLeft = e.pageX + this.dragStartPositionX;
				const dragOffsetTop = e.pageY + this.dragStartPositionY;
				// update modalPositionStyle
				this.$emit('updatePosition', {
					left: dragOffsetLeft,
					top: dragOffsetTop,
				});
			}
		}

		handleMouseUp() {
			if (this.isDraggable && this.enableDrag) {
				this.toggleDragStatus({ drag: false });
				this.dragStartPositionX = 0;
				this.dragStartPositionY = 0;
			}
		}

		toggleDragStatus(payload: { drag: boolean }) {
			this.enableDrag = payload.drag;

			const cursorStyle = payload.drag ? 'grabbing' : 'default';
			(this.$root.$el as HTMLElement).style.cursor = cursorStyle;

			this.toggleEventListeners({ add: payload.drag });
		}

		toggleEventListeners(payload: { add: boolean }) {
			if (payload.add) {
				this.mouseMoveEventObject = this.handleMouseMove.bind(this);
				this.mouseUpEventObject = this.handleMouseUp.bind(this);
				window.addEventListener('mousemove', this.mouseMoveEventObject);
				window.addEventListener('mouseup', this.mouseUpEventObject);
			} else {
				window.removeEventListener('mousemove', this.mouseMoveEventObject);
				window.removeEventListener('mouseup', this.mouseUpEventObject);
				this.mouseMoveEventObject = undefined;
				this.mouseUpEventObject = undefined;
			}
		}

		updateWindowDimension() {
			if (this.isDraggable)
				this.windowDimension = {
					width: window.innerWidth,
					height: window.innerHeight,
				};
		}

		handleControleKeys(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				e.preventDefault();
				e.stopPropagation();
				this.$emit('handleCloseModal', e);
			}
			if (this.disableTabKey && e.key === 'Tab') {
				e.preventDefault();
				e.stopPropagation();
			}
		}

		mounted() {
			this.resizeEventObject = this.updateWindowDimension.bind(this);
			window.addEventListener('resize', this.resizeEventObject);
			document.addEventListener('keydown', this.handleControleKeys);
		}

		beforeDestroy() {
			window.removeEventListener('resize', this.resizeEventObject);
			document.removeEventListener('keydown', this.handleControleKeys);
			this.resizeEventObject = undefined;
		}
	}
