
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';

	import CheckBox from '@pixcap/ui-library/components/CheckBox.vue';
	import IconDownloadSimple from '@pixcap/ui-library/components/Icons/IconDownloadSimple.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import IconUndo from '@pixcap/ui-library/components/Icons/IconUndo.vue';
	import IconGlobeSimple from '@pixcap/ui-library/components/Icons/IconGlobeSimple.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import {
		NAMESPACE as USER_NAMESPACE,
		GetterTypes as UserGettersTypes,
		Getters as UserGetters,
		UPGRADE_PLAN_TRIGGER_TYPE,
		SUBSCRIPTION_UPGRADE_CASE,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { actionsWrapper as ExportActions } from '@pixcap/ui-core/store/projectexports';
	import { ExportAIStyledImageWithTransformation } from '@pixcap/ui-core/models/store/projectexports.interface';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	const FILE_EXTENSION = {
		png: '.png',
		jpg: '.jpg',
	};

	@Component({
		name: 'ImagePreviewModal',
		components: {
			BodyText,
			CheckBox,
			IconDownloadSimple,
			IconClose,
			IconLargeArrowRight,
			Button,
			IconCrown,
			CaptionText,
			IconCaretRight,
			IconUndo,
			IconGlobeSimple,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGettersTypes.IS_PRO_OR_ABOVE,
			}),
		},
	})
	export default class ImagePreviewModal extends Vue {
		@Prop() images: string[] | { url: string; isVideo: boolean }[];
		@Prop() modalTitle?: string | undefined;
		@Prop({ default: false }) showCloseButton: boolean;
		@Prop() previewImageIndex: number;
		@Prop() previewImageTitle: number | undefined;
		@Prop() previewImagePurpose: string | undefined;
		@Prop() aiStyles: { url: string; styleId: string }[];
		@Prop() aiJobId: string;
		@Prop() downloadSize: { width: number; height: number };

		UPGRADE_IMAGE = `${_PIXCAP_ENV.ROOT_URL}/cdn/app/ecommerce_podiums.webp`;
		activeIndex = 0;

		isProOrAbove: ReturnType<UserGetters[UserGettersTypes.IS_PRO_OR_ABOVE]>;

		vcoConfig = {
			handler: this.onModalClickOutside,
			events: ['click'],
		};

		$refs: {
			refImagePreviewModal: HTMLElement;
		};

		get imagesLength() {
			return this.images.length;
		}

		get currentImage() {
			return this.images[this.activeIndex];
		}

		get imageStyleId() {
			if (this.previewImagePurpose !== 'AI_STYLIST_IMAGE' || !this.aiStyles) return null;
			const currentImageObj = this.aiStyles.filter((item) => item.url === this.currentImage)[0];
			return currentImageObj.styleId;
		}

		get activeImageUrl() {
			const activeImage = this.images[this.activeIndex] as any;
			return activeImage.url ?? activeImage;
		}

		get isVideo() {
			const activeImage = this.images[this.activeIndex] as any;
			return activeImage.isVideo;
		}

		handleArrowKeyPress(e: KeyboardEvent) {
			if (e.key == 'ArrowLeft') {
				this.selectPreviousItem();
			} else if (e.key == 'ArrowRight') {
				this.selectNextItem();
			}
		}

		selectPreviousItem() {
			if (this.activeIndex >= 1) {
				this.activeIndex -= 1;
			}
		}

		selectNextItem() {
			if (this.activeIndex < this.images.length - 1) {
				this.activeIndex += 1;
			}
		}

		onModalClickOutside() {
			this.$emit('closeModal');
		}

		async toDataURL(url) {
			const blob = await fetch(url, { cache: 'no-cache' }).then((res) => res.blob());
			return URL.createObjectURL(blob);
		}

		async download() {
			if (!this.images[this.activeIndex]) {
				return;
			}
			const a = document.createElement('a');
			a.addEventListener('click', function (e) {
				e.cancelBubble = true;
			});
			a.href = await this.toDataURL(this.images[this.activeIndex]);
			a.download = this.previewImageTitle ? `${this.previewImageTitle}.png` : 'download.png';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			await this.populateToMyDownloads();
			await this.publishToCommunity();
			if (this.previewImagePurpose == 'AI_STYLIST_IMAGE')
				this.$pixcap.$mixpanel.track(this.aiJobId ? 'M-AI: download' : 'Download AI Stylist Image', {
					Source: this.aiJobId ? 'AI Panel' : 'My Download',
				});
		}

		async populateToMyDownloads() {
			if (this.imageStyleId) {
				await ExportActions.populateImageToMyDownloads(this.$store, {
					width: this.downloadSize.width,
					height: this.downloadSize.height,
					isUpscale: false,
					isRemoveBackground: false,
					imageStyleId: this.imageStyleId,
				});
			}
		}

		async handleDownload(isRemoveBackground = false) {
			if (isRemoveBackground && !this.isProOrAbove) {
				UserMutations.setUpgradeModalState(this.$store, {
					triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PREMIUM_FEATURE,
					triggerType: UPGRADE_PLAN_TRIGGER_TYPE.REMOVE_BACKGROUND,
				});
				return;
			}
			if (!this.isProOrAbove) UserMutations.setShowAttributionExportModal(this.$store, { isAIDownload: true });

			if (!isRemoveBackground) {
				this.download();
			} else {
				const currentImage = this.activeImageUrl;
				const isPng = currentImage.includes(`${FILE_EXTENSION.png}`);
				const imgExtension = isPng ? FILE_EXTENSION.png : FILE_EXTENSION.jpg;
				const imgOpts: ExportAIStyledImageWithTransformation = {
					presignedUrl: currentImage,
					imageStyleId: this.imageStyleId,
					filename: this.previewImageTitle ? `${this.previewImageTitle}${imgExtension}` : `download${imgExtension}`,
					isPng,
					transparentBackground: isPng,
					isRemoveBackground: isRemoveBackground ? true : null,
					width: this.downloadSize?.width,
					height: this.downloadSize?.height,
				};

				const response = await ExportActions.exportAIStyledImageWithTransformation(this.$store, imgOpts);
				if (response.error) {
					this.download();
					return;
				}
				await this.publishToCommunity();
				if (this.previewImagePurpose == 'AI_STYLIST_IMAGE')
					this.$pixcap.$mixpanel.track(this.aiJobId ? 'Download AI Stylist Image' : 'M-AI: download', {
						Source: this.aiJobId ? 'AI Panel' : 'My Download',
						'Is Remove Background': isRemoveBackground,
					});
			}
		}

		async publishToCommunity() {
			if (this.aiJobId) await ExportActions.publishAISceneToCommunity(this.$store, this.aiJobId);
		}

		mounted() {
			this.$nextTick(() => {
				this.$refs.refImagePreviewModal.focus();
			});
		}

		created() {
			this.activeIndex = this.previewImageIndex || 0;
		}
	}
