
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { AppState } from '@pixcap/ui-core/models/store/app.interface';
	import { NAMESPACE as APP_NAMESPACE, MutationTypes as AppMutationTypes } from '@pixcap/ui-core/models/store/app.interface';
	import { mutationsWrapper as AppMutations } from '@pixcap/ui-core/store/app';
	import { mapState } from 'vuex';

	@Component({
		name: 'NetworkStatusModal',
		computed: {
			...mapState(APP_NAMESPACE, {
				networkStatusModal: (state: AppState) => state.networkStatusModal,
			}),
		},
	})
	export default class NetworkStatusModal extends Vue {
		networkStatusModal: AppState['networkStatusModal'];

		get showModal() {
			return this.networkStatusModal && this.networkStatusModal.showModal;
		}

		get networkDisconnected() {
			return this.networkStatusModal && !this.networkStatusModal.networkConnected;
		}

		@Watch('networkDisconnected')
		onNetworkDisconnected(next) {
			if (!next) {
				setTimeout(() => {
					AppMutations.setNetworkStatusModal(this.$store, null);
				}, 1000);
			}
		}
	}
