import { render, staticRenderFns } from "./Cropper.vue?vue&type=template&id=25f8a8d4&scoped=true&"
import script from "./Cropper.vue?vue&type=script&lang=ts&"
export * from "./Cropper.vue?vue&type=script&lang=ts&"
import style0 from "./Cropper.vue?vue&type=style&index=0&id=25f8a8d4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f8a8d4",
  null
  
)

export default component.exports